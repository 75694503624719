import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaLaptopCode } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'ホームページ制作｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      '事業を立ち上げたばかりの方、ブランドをさらに強化したい方へ。ビジネスの目的に合わせた、効果的で魅力的なホームページを制作します。伝えたいメッセージを的確に届け、成果につながるサイトを構築します。',
    pageUrl: 'https://www.involve-in.jp/service/new',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'ホームページ制作', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="ホームページ制作"
        subtitle="新しく作りたい"
        icon={FaLaptopCode}
        description="事業を立ち上げたばかりの方、ブランドをさらに強化したい方へ。ビジネスの目的に合わせた、効果的で魅力的なホームページを制作します。伝えたいメッセージを的確に届け、成果につながるサイトを構築します。"
        seo={{
          title: 'ホームページ制作｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            '事業を立ち上げたばかりの方、ブランドをさらに強化したい方へ。ビジネスの目的に合わせた、効果的で魅力的なホームページを制作します。伝えたいメッセージを的確に届け、成果につながるサイトを構築します。',
          pageUrl: 'https://www.involve-in.jp/service/new',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'ホームページ制作',
            url: 'https://www.involve-in.jp/service/new',
          },
        ]}
        benefits={[
          {
            title: 'WEBがまったくわからない',
            description:
              'ホームページについての知識がなくても、まったく問題ありません。丁寧にサポートしますので、安心してご相談ください。',
          },
          {
            title: '高品質なデザイン',
            description:
              '企業のブランドイメージや雰囲気に合わせた、洗練されたデザインをご提供。第一印象で信頼を生み出すサイトを制作します。',
          },
          {
            title: '予算に合わせた提案',
            description:
              'ご希望の予算内で、最適なプランをご提案。無理なく、効果的なホームページを実現します。',
          },
        ]}
        flow={[
          {
            title: 'ヒアリング',
            duration: null,
            description:
              'お客様のご要望を詳しくお伺いし、最適なプランをご提案します。奈良県外のお客様やスケジュールの都合が合わない場合は、オンラインミーティングにも対応しております。',
          },
          {
            title: 'デザイン作成',
            duration: '2週間',
            description:
              'ヒアリング内容をもとに、サイトのデザインを作成。完成後、画像データにてデザインをご確認いただき、必要に応じて修正を行います。',
          },
          {
            title: 'コーディング・システム開発',
            duration: '2週間',
            description:
              '確定したデザインをもとに、HTMLコーディングを実施。レスポンシブ対応を施し、必要に応じてコンテンツ管理システム（CMS）や問い合わせフォームの開発も行います。',
          },
          {
            title: '公開・納品',
            duration: null,
            description:
              'すべての工程が完了後、テストサイトで最終確認を実施。問題がなければ、正式に公開し、納品完了となります。',
          },
        ]}
        preparation={[
          {
            title: 'ロゴの確認',
            description:
              'ホームページで使用するロゴがあるかご確認ください。データ形式は Ai（Adobe Illustrator） が理想ですが、なければ 大きめのJPGファイル をご用意ください。',
          },
          {
            title: 'サーバー・ドメインの設定',
            description:
              'サーバーやドメインをお持ちですか？未取得の場合は、こちらでご用意することも可能ですので、お気軽にご相談ください。',
          },
          {
            title: '写真や文章の準備',
            description:
              'ホームページに掲載したい写真や文章をご用意ください。必要であれば 出張撮影 や 文章作成 も承りますので、お気軽にご相談ください。',
          },
          {
            title: '希望納期の確認',
            description:
              'いつまでにホームページを完成させたいか、お知らせください。可能な限りご要望に沿えるよう、スケジュールを調整いたします。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥300,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
